/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    h3: "h3",
    em: "em",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Documentation"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://bit.ai/templates/software-requirements-document-template"
  }, "Software requirements document - template - Bit.ai")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.perforce.com/blog/alm/how-write-software-requirements-specification-srs-document"
  }, "Software Specification - Perforce")), "\n", React.createElement(_components.h2, null, "Description"), "\n", React.createElement(_components.p, null, "This document is a general catch from concepts and features that I feel should be referenced during the development of an application so as to ensure quality."), "\n", React.createElement(_components.h2, null, "Virtual Machines"), "\n", React.createElement(_components.h3, null, "Why:"), "\n", React.createElement(_components.p, null, "Due to the diverse nature of operating systems in the digital ecosystem. It is imperative to test the digital product developed on as many configurations as possible thereby ensuring consistent functionality and user experience."), "\n", React.createElement(_components.p, null, "Install common machine configs on a VM(virtual machine). This allows the testing and thereby a degree of quality control across OS's."), "\n", React.createElement(_components.p, null, "Virtual Machines allow a development teams to all run the same configuration, thereby ensuring consistent development.\n", React.createElement(_components.em, null, "The code runs the same where ever the code is executed"), "\n", React.createElement(_components.a, {
    href: "https://www.virtualbox.org/"
  }, "Virtual Box"), "\n", React.createElement(_components.a, {
    href: "https://www.vagrantup.com/"
  }, "Vagrant"), "\n", React.createElement(_components.a, {
    href: ""
  }, "Docker")), "\n", React.createElement(_components.h2, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Lint_(software)"
  }, "Linting")), "\n", React.createElement(_components.p, null, "Goal:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "read-ability"), "\n", React.createElement(_components.li, null, "consistency"), "\n", React.createElement(_components.li, null, "Syntax"), "\n"), "\n", React.createElement(_components.p, null, "Function/Method/How:\nChecks code against a given set of rules.\nOptions:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "lint code manually via CLI"), "\n", React.createElement(_components.li, null, "text editor plugin -> lints as code is produced"), "\n"), "\n", React.createElement(_components.h2, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Unit_testing"
  }, "Unit Testing")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "The process of checking small chunks of code to see if they work as expected")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "function"), "\n", React.createElement(_components.li, null, "objects"), "\n", React.createElement(_components.li, null, "methods"), "\n"), "\n", React.createElement(_components.h3, null, "Frameworks for Unit testing"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://jasmine.github.io/"
  }, "Jasmine"), "\n", React.createElement(_components.a, {
    href: "https://karma-runner.github.io/2.0/index.html"
  }, "Karma")), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: ""
  }, "End to End Test")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Testing the program by emulating a user behavior")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.protractortest.org/#/"
  }, "Protractor")), "\n", React.createElement(_components.h2, null, "Building"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://gulpjs.com/"
  }, "Gulp"), "\n", React.createElement(_components.a, {
    href: "https://gruntjs.com/"
  }, "Grunt"), "\n", React.createElement(_components.a, {
    href: "https://yarnpkg.com/en/"
  }, "yarn"), "\n", React.createElement(_components.a, {
    href: "https://webpack.js.org"
  }, "webpack")), "\n", React.createElement(_components.h2, null, "Continuous Integration (CI)"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Build and test code in an automated way"), "\n", React.createElement(_components.a, {
    href: "https://travis-ci.org/"
  }, "Travis CI"), "\n", React.createElement(_components.a, {
    href: "https://circleci.com/"
  }, "Circle CI"), "\n", React.createElement(_components.a, {
    href: "https://codeship.com/"
  }, "Code ship"), "\n", React.createElement(_components.a, {
    href: "https://jenkins.io/"
  }, "Jenkins")), "\n", React.createElement(_components.h2, null, "Continuous Delivery (CD)"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Keeping code in a state of readiness to be pushed into delivery")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Choose when to deploy new features"), "\n"), "\n", React.createElement(_components.h2, null, "Continuous Deployment (CD)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Deploy features as soon as they are ready"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
